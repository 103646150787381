import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import allROUTES from "./router";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AllRouter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header />
      <main>
        <Routes>
          {allROUTES.map((page) => (
            <Route
              key={page.id}
              path={page.path}
              element={<page.component title={page.title} />}
            />
          ))}
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default AllRouter;
