import React, { useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
`;

const PageButton = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.labelColor || "#007BFF" : "#f0f0f0"};
  color: ${({ active, theme }) =>
    active ? "#fff" : theme.colors.text || "#333"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: background-color 0.3s, color 0.3s;
`;

const Ellipsis = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text || "#333"};
`;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    pages.push(
      <PageButton
        key={1}
        active={1 === currentPage}
        onClick={() => handleClick(1)}
      >
        1
      </PageButton>
    );

    if (currentPage > 3) {
      pages.push(<Ellipsis key="ellipsis-start">...</Ellipsis>);
    }
    if (currentPage > 2 && currentPage < totalPages - 1) {
      pages.push(
        <PageButton
          key={currentPage}
          active={true}
          onClick={() => handleClick(currentPage)}
        >
          {currentPage}
        </PageButton>
      );
    }

    if (currentPage < totalPages - 2) {
      pages.push(<Ellipsis key="ellipsis-end">...</Ellipsis>);
    }

    if (totalPages > 1) {
      pages.push(
        <PageButton
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handleClick(totalPages)}
        >
          {totalPages}
        </PageButton>
      );
    }

    return pages;
  };

  return (
    <PaginationContainer>
      <PageButton
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      >
        <IoIosArrowBack />
      </PageButton>
      {renderPageNumbers()}
      <PageButton
        disabled={currentPage === totalPages}
        onClick={() => handleClick(currentPage + 1)}
      >
        <IoIosArrowForward />
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;
