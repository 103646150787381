import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Container from "../../layout/Container";
import allROUTES from "../../routes/router";
import Search from "../Search";
import { getItemsCount } from "../../redux/cart";

const HeaderContent = styled.header`
  background: ${({ theme }) => theme.colors.primary};
  padding: 20px 0;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;

const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 786px) {
    order: -1;
  }
`;

const HeaderList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

const HeaderLogo = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: transform 0.3s ease, color 0.3s ease;

  & p {
    background: ${({ theme }) => theme.colors.labelColor};
    padding: 6px 12px;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    border-radius: ${({ theme }) => theme.sizes.borderRadius};
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  @media (max-width: 786px) {
    order: -1;
  }
`;

const HeaderLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 22px;
  font-weight: 500;

  & p {
    position: absolute;
    background: ${({ theme }) => theme.colors.labelColor};
    color: ${({ theme }) => theme.colors.primary};
    top: -10px;
    right: -12px;
    border-radius: 50%;
    font-size: 12px;
    min-width: 22px;
    min-height: 22px;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    white-space: nowrap;
  }
`;

const Header = () => {
  const totalQuantity = useSelector(getItemsCount);

  return (
    <HeaderContent>
      <HeaderContainer>
        <HeaderLogo to="/">
          Allfa <p>BRO</p>
        </HeaderLogo>
        <Search />
        <HeaderItems>
          <HeaderList>
            {allROUTES.map((route) => (
              <li key={route.id}>
                <HeaderLink to={route.path}>
                  {route.icon}
                  {route.path === "/cart" && (
                    <p>{totalQuantity > 999 ? "999+" : totalQuantity}</p>
                  )}
                </HeaderLink>
              </li>
            ))}
          </HeaderList>
        </HeaderItems>
      </HeaderContainer>
    </HeaderContent>
  );
};

export default Header;
