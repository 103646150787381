import React from "react";
import { Helmet as HelmetContent } from "react-helmet";

const Helmet = ({ title, description }) => {
  return (
    <HelmetContent>
      <title>{title} | ALLFA BRO</title>
      <meta name="description" content={description} />
    </HelmetContent>
  );
};

export default Helmet;
