import { LuShoppingCart } from "react-icons/lu";
import { Home, Cart } from "../pages";

const allROUTES = [
  {
    id: 0,
    path: "/",
    component: Home,
    title: "Home Page",
  },
  {
    id: 2,
    path: "/cart",
    component: Cart,
    title: "Cart Page",
    icon: <LuShoppingCart />,
  },
];

export default allROUTES;
