import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import CartItem from "../../components/CartItem";
import Container from "../../layout/Container";
import CartItemDetails from "../../components/CartItemDetails";
import Helmet from "../../layout/Helmet";
import ModalWindow from "../../components/ModalWindow";

const CartContainer = styled(Container)`
  margin: 20px auto 50px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
`;

const CartLeft = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const CartRight = styled.div`
  width: 27%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const Cart = ({ title }) => {
  const { cart } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);

  const items = Object.values(cart);
  const emptyCart = items.length === 0;

  const totalPrice = items.reduce(
    (acc, item) => acc + item.quantity * item["Цена продажи"],
    0
  );

  const totalQuantity = items.reduce((acc, item) => acc + item.quantity, 0);
  const handleOpenModal = () => setShowModal((prev) => !prev);
  const emptyText = `Sizning savatingiz hozir bo'sh 🙄`;

  return (
    <>
      <Helmet title={title} />
      <Title>{emptyCart ? emptyText : "Savat"}</Title>
      <CartContainer>
        <CartLeft>
          {items.map((item) => (
            <CartItem item={item} />
          ))}
        </CartLeft>
        {!emptyCart && (
          <CartRight>
            <CartItemDetails
              totalPrice={totalPrice}
              totalQuantity={totalQuantity}
              openModal={handleOpenModal}
            />
          </CartRight>
        )}
      </CartContainer>
      {showModal && <ModalWindow closeModal={handleOpenModal} />}
    </>
  );
};

export default Cart;
