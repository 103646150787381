import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addToCart, removeFromCart } from "../../redux/cart";

const SearchForm = styled.form`
  max-width: 700px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: relative;

  @media (max-width: 992px) {
    max-width: 500px;
  }
  @media (max-width: 786px) {
    max-width: 100%;
  }
  @media (max-width: 678px) {
    max-width: 100%;
    order: 3;
    margin-top: 10px;
  }
`;

const SearchFormInput = styled.input`
  flex-grow: 1;
  padding: 10px 15px;
  background-color: #fafafa;
  border: none;
  border-radius: 7px 0 0 7px;
  font-size: 16px;
  outline: none;
  color: #333;
  height: 100%;
`;

const SearchFormButton = styled.button`
  width: 100px;
  background: ${({ theme }) => theme.colors.labelColor || "#007bff"};
  border: none;
  border-radius: 0 7px 7px 0;
  color: white;
  font-size: 18px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  height: 100%;
`;

const DropdownResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 10;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const DropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  border-bottom: 1px solid #f1f1f1;
  padding: 10px;

  h4 {
    font-size: 16px;
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
    color: #555;
  }
`;

const Button = styled.button`
  width: 30%;
  background: ${({ theme, select }) =>
    !select ? theme.colors.labelColor : theme.colors.primary};
  color: ${({ theme, select }) =>
    !select ? theme.colors.primary : theme.colors.labelColor};
  border: 1px solid ${({ theme }) => theme.colors.labelColor};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: 10px 0;
  cursor: pointer;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const Search = () => {
  const [term, setTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("data")) || [];
    setData(storedData);
  }, []);

  const handleToCart = (product, barcode) => {
    if (cart[barcode]) {
      dispatch(removeFromCart(barcode));
    } else {
      dispatch(addToCart(product));
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setTerm(searchTerm);
    const filtered = data.filter((item) =>
      item["Наименование товара"].toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const formatPrice = (price) =>
    price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <SearchForm onSubmit={(e) => e.preventDefault()}>
      <SearchFormInput
        type="search"
        placeholder="Mahsulotlar izlash"
        value={term}
        onChange={handleSearch}
      />
      <SearchFormButton type="submit">
        <FaSearch />
      </SearchFormButton>
      <DropdownResults show={term && filteredData.length > 0}>
        {filteredData.map((item, index) => (
          <DropdownItem key={index}>
            <h4>{item["Наименование товара"]}</h4>
            <p>{formatPrice(item["Цена продажи"])} so'm</p>
            <Button
              select={cart[item["Баркод"]]}
              onClick={() => handleToCart(item, item["Баркод"])}
            >
              {cart[item["Баркод"]] ? "Qo'shilgan" : "Savatga qo'shish"}
            </Button>
          </DropdownItem>
        ))}
      </DropdownResults>
    </SearchForm>
  );
};

export default Search;
