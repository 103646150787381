import React from "react";
import styled from "styled-components";

const LoadingSpinnerItem = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid #fcedd6;
  border-top: 4px solid ${({ theme }) => theme.colors.labelColor};
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerItem>
      <Spinner></Spinner>
    </LoadingSpinnerItem>
  );
};

export default LoadingSpinner;
