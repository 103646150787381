import React from "react";
import styled from "styled-components";
import Container from "../../layout/Container";

const FooterBlog = styled.footer`
  background-color: #1a1a1a;
  color: ${({ theme }) => theme.colors.primary};
  padding: 40px 0;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.labelColor};
    }
  }
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterListItem = styled.li`
  margin-bottom: 10px;
`;

const FooterLabel = styled.h3`
  font-size: 18px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.labelColor};
`;

const FooterBottom = styled.div`
  margin-top: 40px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #333333;
  padding-top: 20px;
`;

const Footer = () => {
  return (
    <FooterBlog>
      <Container>
        <FooterTop>
          <FooterSection>
            <FooterLabel>Biz haqimizda</FooterLabel>
            <p>
              Internet do‘konimiz orqali siz yuqori sifatli mahsulotlarni qulay
              narxlarda xarid qilishingiz mumkin.
            </p>
          </FooterSection>
          <FooterSection>
            <FooterLabel>Aloqa</FooterLabel>
            <FooterList>
              <FooterListItem>Telefon: +998 (90) 123-45-67</FooterListItem>
              <FooterListItem>Email: support@example.com</FooterListItem>
            </FooterList>
          </FooterSection>
          <FooterSection>
            <FooterLabel>Ijtimoiy tarmoqlar</FooterLabel>
            <FooterList>
              <FooterListItem>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </FooterListItem>
              <FooterListItem>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </FooterListItem>
            </FooterList>
          </FooterSection>
        </FooterTop>
        <FooterBottom>
          &copy; {new Date().getFullYear()} Internet Magazin. Barcha huquqlar
          himoyalangan.
        </FooterBottom>
      </Container>
    </FooterBlog>
  );
};

export default Footer;
