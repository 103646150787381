import React from "react";
import styled from "styled-components";
import { TbShoppingBagPlus } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart";

const CardContainer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  max-width: 280px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
`;

const CardImg = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  background-color: #f2f2f2;
`;

const InfoContainer = styled.div`
  padding: 15px;
  background: ${({ theme }) => theme.colors.primary};
`;

const ProductTitle = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  margin: 0 0 8px;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  margin: 0 0 5px;
`;

const Button = styled.button`
  width: 100%;
  background: ${({ theme, select }) =>
    !select ? theme.colors.labelColor : theme.colors.primary};
  color: ${({ theme, select }) =>
    !select ? theme.colors.primary : theme.colors.labelColor};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background 0.3s ease;
  border: 1px solid ${({ theme }) => theme.colors.labelColor};

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.labelColor};
  }

  svg {
    margin-left: 8px;
  }
`;

const Card = ({ product, select }) => {
  const dispatch = useDispatch();
  const handleSelect = () => {
    dispatch(select ? removeFromCart(product["Баркод"]) : addToCart(product));
  };

  const formatPrice = (price) =>
    price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <CardContainer>
      <CardImg src={product.images} alt={product["Наименование товара"]} />
      <InfoContainer>
        <ProductTitle>{product["Наименование товара"]}</ProductTitle>
        <ProductPrice>{formatPrice(product["Цена продажи"])} so'm</ProductPrice>
        <Button select={select} onClick={handleSelect}>
          {select ? "Qo'shilgan" : "Savatga qo'shish"}
          <TbShoppingBagPlus />
        </Button>
      </InfoContainer>
    </CardContainer>
  );
};

export default Card;
