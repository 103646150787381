import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: 1px solid rgba(54, 55, 64, 0.08);
`;

const Title = styled.h2`
  margin-top: 0;
  color: ${({ theme }) => theme.colors.labelColor};
  margin-bottom: 20px;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 500;
`;

const Price = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
  font-weight: bold;
`;

const Button = styled.button`
  border: none;
  width: 100%;
  line-height: 45px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.labelColor};
`;

const CartItemDetails = ({ openModal, totalPrice, totalQuantity }) => {
  const formatPrice = (price) =>
    price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  console.log(totalPrice, "price");

  return (
    <Container>
      <Title>Sizning buyurtmangiz</Title>
      <Block>
        <Label>Ummumiy summa: </Label>{" "}
        <Price>{formatPrice(totalPrice)} so'm</Price>
      </Block>
      <Block>
        <Label>Ummumiy miqdor: </Label> <Price>{totalQuantity}</Price>
      </Block>
      <Button onClick={openModal}>Rasmiylashtirish</Button>
    </Container>
  );
};

export default CartItemDetails;
