import React from "react";
import AllRouter from "./routes/AllRouter";

const App = () => {
  return (
    <div className="App">
      <AllRouter />
    </div>
  );
};
export default App;
