const theme = {
  colors: {
    primary: "#fff",
    secondary: "#2ecc71",
    background: "#f5f5f5",
    text: "#333333",
    border: "#dddddd",
    labelColor: "#f5a623",
  },
  sizes: {
    borderRadius: "8px",
  },
};

export default theme;
