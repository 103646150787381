import React from "react";
import styled from "styled-components";
import { BsTrash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  addOne,
  removeFromCart,
  removeOne,
  updateQuantity,
} from "../../redux/cart";

const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 578px) {
    flex-direction: column;
  }
`;

const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  object-fit: cover;
`;

const ProductDetails = styled.div`
  flex: 1;
  margin-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }

  p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
  }
`;

const Price = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 152px;
  width: 100%;
  margin-right: 20px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: 1px solid rgba(54, 55, 64, 0.08);

  button {
    padding: 10px;
    background: ${({ theme }) => theme.colors.labelColor};
    border: none;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;

    &:hover {
      background-color: #feb33a;
    }
  }
  input {
    width: 100px;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }

  @media (max-width: 578px) {
    margin: 20px 0;
    justify-content: center;
  }
`;

const TrashIcon = styled(BsTrash)`
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: #ff0000;
  }

  @media (max-width: 578px) {
    margin-left: 0;
    font-size: 22px;
  }
`;

const CartItem = ({ item }) => {
  const dispatch = useDispatch();
  const totalPrice = item.quantity * item["Цена продажи"];

  const handleIncrement = () => dispatch(addOne(item["Баркод"]));
  const handleDecrement = () => dispatch(removeOne(item["Баркод"]));
  const handleRemoveFromCart = () => dispatch(removeFromCart(item["Баркод"]));

  const handleChangeQuantity = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      dispatch(updateQuantity({ id: item["Баркод"], quantity: newQuantity }));
    }
  };

  const formatPrice = (price) =>
    price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <CartItemContainer>
      <ProductImage src={item.images} alt={item["Наименование товара"]} />
      <ProductDetails>
        <div>
          <h3>{item["Наименование товара"]}</h3>
          <Price>so'm {formatPrice(totalPrice)}</Price>
        </div>
      </ProductDetails>
      <Actions>
        <button onClick={handleDecrement}>-</button>
        <input
          type="text"
          value={item.quantity}
          onChange={handleChangeQuantity}
        />
        <button onClick={handleIncrement}>+</button>
      </Actions>
      <TrashIcon onClick={handleRemoveFromCart} />
    </CartItemContainer>
  );
};

export default CartItem;
