import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LoadingSpinner from "../../components/Loading";
import Card from "../../layout/Card";
import Pagination from "../../layout/Pagination";
import Container from "../../layout/Container";

const ITEMS_PER_PAGE = 20;

const CardContent = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  min-height: 100vh;
  height: 100%;

  @media (max-width: 1224px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0;
`;

const Home = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { cart } = useSelector((state) => state);

  useEffect(() => {
    const fetchExcelFile = async () => {
      setLoading(true);
      try {
        const response = await fetch("/AllProducts.xlsx");
        const blob = await response.blob();
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          setData(jsonData);
          localStorage.setItem("data", JSON.stringify(jsonData));
        };
        fileReader.readAsArrayBuffer(blob);
      } catch (error) {
        console.error("Excel faylni yuklashda xatolik:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExcelFile();
  }, []);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => setCurrentPage(page);

  if (loading) return <LoadingSpinner />;

  return (
    <div>
      <Title>Barcha Mahsulotlar</Title>
      <CardContent>
        {currentData.map((product, index) => (
          <Card
            key={index}
            product={product}
            select={product["Баркод"] in cart}
          />
        ))}
      </CardContent>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data.length / ITEMS_PER_PAGE)}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Home;
