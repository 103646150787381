import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RiCloseLargeLine } from "react-icons/ri";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 30px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  max-width: 500px;
  width: 100%;

  & p {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 5px;
  background: none;
  color: ${({ theme }) => theme.colors.text};
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};

  & span {
    color: red;
    font-size: 18px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.border};
  resize: none;
`;

const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.labelColor};
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  width: 100%;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  cursor: pointer;
  margin-top: 15px;
`;

const ModalWindow = ({ showModal, closeModal }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    message: "",
    location: "",
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <ModalOverlay show={showModal}>
      <ModalContent>
        <p>Mahsulotlarni rasmiylashtirish</p>
        <CloseButton onClick={closeModal}>
          <RiCloseLargeLine />
        </CloseButton>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="fullName">
              Ism familiya <span>*</span>
            </Label>
            <Input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              required
              placeholder="Aziz Azizov"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phoneNumber">
              Telefon raqam <span>*</span>
            </Label>
            <Input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
              placeholder="+998970007766"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="location">
              Yetqazil beriladigan manzil <span>*</span>
            </Label>
            <Input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              required
              placeholder="Toshkent Shahari, Chilonzor tumani, Bunyodkor ko'cha 32 Uy"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message">Xabar</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows="4"
              placeholder=""
            />
          </FormGroup>
          <SubmitButton type="submit">Yuborish</SubmitButton>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalWindow;
